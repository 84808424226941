.flex{
  display: flex;
  align-items: center;
}
.navBar{
  position: fixed;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
}
.navBarOne, .navBarTwo{
  justify-content: space-between;
  width: 100%;
}
li{
  list-style: none;
  cursor: pointer;
}
.navBarTwo{
  display: flex;
  align-items: center;
  flex: end;
  top: 8px;
  position: fixed;
  transition: .3s ease-in-out;

}
.listItem {
  position: relative;  
  text-decoration: none; 
}
.listItem::before {
  content: ''; 
  position: absolute; 
  bottom: -2px; 
  left: 0;
  width: 0; 
  height: 2px; 
  background-color: #000; 
  transition: width 0.5s ease-in-out;
}

.listItem:hover::before {
  width: 100%; 
}
.toggleIcon{
  display: none;
}
@media screen and (max-width: 768px) {
  .navBarMenu{
    position:absolute;
    padding: 1.5rem;
    border-radius: 10px;
    top: 5rem;
    right:  -50%;
    width: 80%;
    transform: translateX(50%);
    z-index: 2000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,rgba(0,0,0,0.05) 0px 4px 6px -2px ;
    border: 3px solid white;
    transition: .4s ease-in-out ;
  }
  .toggleIcon{
      display: block;
  }
  .menu{
    flex-direction: column;
    gap: .5rem;
    text-align: center;
    }
    .listItem{
      padding: .5rem 0;
      cursor: pointer;
  }
  .listItem:hover{
      color: blue;
      font-weight: 700;
    }
  
  .icon1{
    font-size: 30px;
  }

.showNavBar{
  right: 50%;
  transition: .5s ease-in-out;
}
}