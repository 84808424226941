.hero-container {
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.content{
    margin-top: 240px;
}
.landing-content{
    font-size: 50px;
    line-height: 60px;
    font-weight: bolder;
}

@media only screen and (max-width: 700px) {
    .landing-content{
        font-size: 25px;
        line-height: 30px;
    }
}