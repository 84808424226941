.header5{
    margin-top: 300px;
    margin-bottom: 250px;
}
.career-vido-caption {
    font-size: 35px;
    width: 250px;
    line-height: 30px;
    font-weight: 300;
    color: hwb(0 100% 0%);
    position: relative;
    left: 24px;
    bottom: 65px;
    transition: all 0.5s ease;
    transition-duration: 1s;
}

.sys-hero2{
    position: relative;
	overflow: hidden;
	cursor: pointer;    
}

.sys-img1{
    transition: 1.5s;
    object-fit: cover;
}

.sys-hero2:hover .sys-img1{
    transform: scale(1.10);
    transition: 1.5s;
}

.career-vido-caption:hover .sys-img1{
    transform: scale(1.10);
    transition: 1.5s;
}

.careers-employeespeak {
    background-color: #f3f3f3;
    color: #000;
    max-width: 420px;
    padding: 35px;
    position: relative;
    margin-left: auto;
    margin-right: 70px;
    margin-top: -110px;
    margin-bottom: 30px;
    text-align: left;
}

.employeespeak-head {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}

.carousel-content {
    font-size: 16px; 
}
.sys-img2 {
    width: 20%;
    padding-bottom: 0;
}
h5 {
    font-size: 20px; 
}
.img-employee {
    border-radius: 50%;
    display: inline-block;
    height: 85px;
    margin-right: 10px;
    width: 85px !important;
}
.slide{
    height: 190px;
}
.indicators{
    margin-top: -70px;
}
.slides-btn{
    margin-bottom: 0px;
}

@media screen and (max-width: 1000px) {
    .career-vido-caption {
        font-size: 15px;
        width: 190px;
        line-height: 30px;
        font-weight: 300;
        color: hwb(0 100% 0%);
        position: relative;
        left: -35px;
        bottom: 30px;
        transition: all 0.5s ease;
        transition-duration: 1s;
    }
    .careers-employeespeak {
        margin-right: 20px;
        margin-top: -70px;
        margin-bottom: 60px;    
        max-width: 200px;
        padding: 7px;
    }
    .employeespeak-head {
        color: #333;
        font-size: 12px;
        padding-left: 0px;
        margin-bottom: 3px;
    }
    .carousel-content {
        font-size: 12px;
        margin-bottom: 50px;
    }
    h5{
        font-size: 14px;
    }
    .sys-img2{
        width: 13%;
        padding-bottom: 4px;
    }
    .slide{
        height: 100px;
    }    
    .slides-btn{
        margin-bottom: -18px;
    }
  } 
