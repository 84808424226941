.header2{
    margin-top: 300px;
    margin-bottom: 250px;
}
.dUxfXO {
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    align-self: center;
    margin-top: 0.625rem;
}
.bDLIOO {
    width: fit-content;
    height: fit-content;
}
.span1 {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 70%;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    max-width: 100%;
}

.span2 {
    box-sizing: border-box;
    display: block;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    max-width: 100%;
}
.image1 {
    display: block;
    max-width: 100%;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
}
.image2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-radius: 10px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}
.image-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chat-btn{
    width: 90%;
}
  
@media only screen and (max-width: 600px) {
    .chat-btn{
        width: 75%;
    }
}