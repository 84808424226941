.header6{
    margin-top: 300px;
    margin-bottom: 250px;
}
a{
    text-decoration: none;
    float: right;
}
.access-box{
    border-radius: 10px;
    box-shadow: 3px 2px 5px 2px rgb(226, 222, 222);
    padding: 26px;
}