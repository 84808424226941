.scroll-to-top-button {
    display: none;
    position: fixed;
    color: rgb(14, 1, 1);
    background-color: rgb(168, 226, 239);
    border: 1px solid rgb(182, 171, 171);
    border-radius: 80%;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    transition: opacity 1s;
  }
  
  /* @media only screen and (max-width: 600px) {
    .scroll-to-top-button {
        display: none;
        position: fixed;
        color: rgb(14, 1, 1);
        background-color: rgb(168, 226, 239);
        border: 1px solid rgb(182, 171, 171);
        border-radius: 50%;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        font-size: 20px;
        transition: opacity 1s;
      }

  } */