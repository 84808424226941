.header7{
    margin-top: 300px;
    margin-bottom: 250px;
}
.sys-hero1{
    position: relative;
	overflow: hidden;
	cursor: pointer;    
}

.sys-img1{
    transition: 1s;
    object-fit: cover;
}

.sys-hero1:hover .sys-img1{
    transform: scale(1.20);
    transition: 1s;
}

.bottom-gradient-do_more {
    background: transparent;
    inset: 0px;
    position: absolute;
    transition: all 0.5s ease 0s;
}

.sys-hero1:hover .bottom-gradient-do_more {
    background: rgb(56, 34, 97);
    opacity: 0.8;
}

.sys-herohead{
    padding-top: 25px;
}

.info {
    backface-visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 40px;
    transform: translateY(10%);
    transition: 0.7s;
    background: rgb(56, 34, 97);
    padding: 5px 20px;
    border-right: 1px solid rgba(150, 53, 150, 1);
}

.do_more-grid-title {
    color: #fff;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-shadow: 0 0 15px #000;
}

.do_more-grid-desc {
    color: #fff;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 14px;
    text-shadow: 0 0 15px #000;
}

.hidden-1024 { 
    color: #fff;
    font-size: 18px;
    font-weight: 100;
    line-height: 27px;
}


.sys-hero1:hover .info {
    transform: translateY(-500%);
    transition: 0.7s;
    background: transparent;
  }

  @media screen and (max-width: 1000px) {
    .sys-head{
        padding-bottom: 30px;
    }

    .sys-herohead{
        padding-top: 0px;
    }
    
  } 






/* .sys-hero1{
    position: relative;
	overflow: hidden;
	cursor: pointer;    
    border-radius: 10px;
}

.sys-img1{
    transition: 1s;
    object-fit: cover;
}

.sys-hero1:hover .sys-img1{
    transform: scale(1.20);
    transition: 1s;
}

.bottom-gradient-do_more {
    background: transparent;
    inset: 0px;
    position: absolute;
    transition: all 0.5s ease 0s;
}

.sys-hero1:hover .bottom-gradient-do_more {
    background: rgb(56, 34, 97);
    opacity: 0.8;
}

.sys-herohead{
    padding-top: 17px;
}

.info {
    backface-visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 42px;
    transform: translateY(10%);
    transition: 0.7s;
    background: rgb(56, 34, 97);
    padding: 5px 20px;
    border-right: 1px solid rgba(150, 53, 150, 1);
}

.do_more-grid-title {
    color: #fff;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-shadow: 0 0 15px #000;
}

.do_more-grid-desc {
    color: #fff;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 14px;
    text-shadow: 0 0 15px #000;
}

.hidden-1024 { 
    color: #fff;
    font-size: 18px;
    font-weight: 100;
    line-height: 27px;
}


.sys-hero1:hover .info {
    transform: translateY(-380%);
    transition: 0.7s;
    background: transparent;
  }

  @media screen and (max-width: 1000px) {
    .sys-head{
        padding-bottom: 30px;
    }

    .sys-herohead{
        padding-top: 0px;
    }
    
  }  */
