.nav-link {
    color: #333;
    font-weight: lighter;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color 0.2s ease-in-out;
}
.nav-link:hover {
  text-decoration: underline;
}
.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}