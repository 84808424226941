.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .carousel {
    display: flex;
    overflow: hidden;
  }
  
  .slide {
    flex: 0 0 100%;
    height: 300px;
    transition: transform 0.3s ease-in-out;
  }
  
  .slide.active {
    transform: translateX(0);
  }
  
  .prev-button,
  .next-button {
    cursor: pointer;
    font-size: 24px;
    margin: 0 10px;
    padding: 5px;
    background: none;
    border: none;
    outline: none;
  }
  
  .prev-button:hover,
  .next-button:hover {
    color: #ff5733;
  }
  