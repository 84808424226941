.header4{
    margin-top: 300px;
    margin-bottom: 250px;
}
.heading{
    color: #1f4288;
}
.image{
    border-radius: 10px;
    width: 90%;
}
.square-box1, .square-box2, .square-box3,
.square-box4, .square-box5, .square-box6{
    width: 200px;
    height: 200px;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: -1;
} 

.square-box1 {
    background-color: #ae76ef;
    top: 33%;
    left: 42%;
}
.square-box2 {
    background-color: #ae76ef;
    top: 47.5%;
    left: 23.5%;
}
.square-box3 {
    background-color: #7FD4E7;
    top: 79%;
    left: 77%;
}
.square-box4 {
    background-color: #7FD4E7;
    top: 93.5%;
    left: 58%;
}
.square-box5 {
    background-color: #68B889;
    top: 125%;
    left: 42%;
}
.square-box6 {
    background-color: #68B889;
    top: 138%;
    left: 23.5%;
}
