.header3{
    margin-top: 300px;
    margin-bottom: 250px;
}

.heading5{
    padding-top: 10px;
    padding-left: 80px;
    margin-bottom: 100px;
}

.hero5{
    font-size: 3.5rem;
    line-height: 1.1;
    padding-bottom: 40px;
    font-weight: bolder;
}

.herocontant5{
    font-size: 1.2rem;
    padding-bottom: 40px;
    color: rgb(106, 105, 105);
    font-weight: 15px;

}

.herobutton5{
    padding-bottom: 40px;
}

.button5{
    border-radius: 15px;
    width: 200px;
    padding: 17px;
    background-color: rgb(249, 132, 78);
    color: rgb(235, 235, 235);
    font-weight: bold;
    border: none;
    font-size: 1.2rem;
}

.box5{
    margin: 30px;
    margin-top: 10px;
    /* background-color: #eff9f7; */
    border-radius: 10px;
}

.img-curve{
    border-radius: 8px;
}

.curve{
    /* border: 2px solid black; */
    border-radius: 30px;
}

.img-container {
    border: 20px solid rgba(0, 187, 119, 0.1);
    border-radius: 20px;
    margin: 30px 0;
    perspective: 1000px;
    padding: 0 12px 0 12px;
}

.img-container.left .hero-section {
    border-radius: 20px;
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 2%), -13px 20px 125px -25px rgb(0 0 0 / 10%), -12px 20px 75px -37.5px rgb(0 0 0 / 20%);
    transform: scale(1.05) translateX(30px) translateY(0) perspective(4000px) rotateY(20deg) rotateX(5deg) rotate(-2deg);
}

.img-container .hero-section {
    margin: -60px 0px -60px 0px;
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 2%), 39px 62.5px 125px -25px rgb(0 0 0 / 10%), 23.4px 37.5px 75px -37.5px rgb(0 0 0 / 20%);
    transition: 0.5s;
    border-radius: 10px;
}

.img-container .hero-section:hover {
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 2%), -13px 20px 125px -25px rgb(0 0 0 / 10%), -12px 20px 75px -37.5px rgb(0 0 0 / 20%);
    transform: none;
    transition: 0.5s;
}

.navs-tabs{
    padding-top: 20px;
}


@media screen and (max-width: 1000px) {    
    .box5{
        margin: 3px;
    }

    .navs-tabs{
        padding-top: 50px;
        padding-bottom: 30px;
    
    }
    
    .heroheading5{
        margin-bottom: 10px;
        padding-left: 10px;
    }
    
    .heading5{
        padding-top: 50px;
        padding-left: 10px;
    }
       
    .hero5{
        font-size: 2.3rem;
        padding-bottom: 30px;
    }

    .herocontant5{
        font-size: 1rem;
        padding-bottom: 40px;
    } 
    
    .heroending5{
        font-size: 1rem;
        padding-bottom: 20px;
    }
    
    .header5{
        padding: 0 0.8rem;
    }
    
    .heading5{
        padding-top: 0px;
    }
    
    .button5{
        padding: 13px;
    }
    
}
