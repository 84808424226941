.contact-details{
    color: #afa4a4;
}
::placeholder {
    padding: 0px 5px 5px 0px;
    color: #b4a2a2bc;
}
.input-field{
    padding: 5px 5px 5px 15px;
    background-color: #9e8b8b3a;
    color: white;

}